function sectionComparisonSectionFirstAnimation(selector = ".comparison-section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();
      navTL = gsap.timeline();
      rowTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // comparison-nav
      if (section.querySelector(".comparison-nav")) {
        const block = section.querySelector(".comparison-nav");
        navTL.fromTo(
          section.querySelector(".comparison-nav"),
          { opacity: 0 },
          { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
        );
        // comparison-nav__item
        if (block.querySelector(".comparison-nav__item")) {
          block.querySelectorAll(".comparison-nav__item").forEach(function (item) {
            navTL.fromTo(
              item,
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${delayAnimationDuration}`
            );
          });
        }
      }

      // comparison-section__row
      if (section.querySelector(".comparison-section__row")) {
        section.querySelectorAll(".comparison-section__row").forEach(function (block) {
          rowTL = gsap.timeline();

          rowTL.fromTo(
            block,
            { opacity: 0 },
            { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );

          // comparison-section__item
          if (block.querySelector(".comparison-section__item")) {
            block.querySelectorAll(".comparison-section__item").forEach(function (item) {
              const itemTL = gsap.timeline();

              itemTL.fromTo(
                item,
                { opacity: 0 },
                { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault" }
              );

              // comparison-section__title
              if (item.querySelector(".comparison-section__title")) {
                itemTL.fromTo(
                  item.querySelector(".comparison-section__title"),
                  { opacity: 0, y: 20 },
                  { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
                  `-=${delayMediumAnimationDuration}`
                );
              }

              // comparison-section__list
              if (item.querySelector(".comparison-section__list")) {
                const list = item.querySelector(".comparison-section__list");
                itemTL.fromTo(
                  item.querySelector(".comparison-section__list"),
                  { opacity: 0, y: 40 },
                  { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
                  `-=${delayMediumAnimationDuration}`
                );

                // li.text
                if (list.querySelector("li.text")) {
                  item.querySelectorAll("li.text").forEach(function (li) {
                    itemTL.fromTo(
                      li,
                      { opacity: 0, y: 20 },
                      { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
                      `-=${delayMediumAnimationDuration}`
                    );
                  });
                }
              }

              ScrollTrigger.create({
                animation: itemTL,
                trigger: block,
                start: "top 80%",
                end: "bottom 20%",
                scrub: false,
              });
            });
          }

          ScrollTrigger.create({
            animation: rowTL,
            trigger: block,
            start: "top 80%",
            end: "bottom 20%",
            scrub: false,
          });

        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });

      ScrollTrigger.create({
        animation: navTL,
        trigger: section.querySelector(".comparison-nav"),
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
