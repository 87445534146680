function allSectionFirstAnimation(selector = ".section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();
      let sectionTarget;

      if (!section.querySelector(".section")) {
        // section__head
        if (section.querySelector(".section__head")) {
          sectionTL.fromTo(
            section.querySelector(".section__head"),
            { opacity: 0 },
            { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
          );
          sectionTarget = true;
        }

        // section__title
        if (section.querySelector(".section__title")) {
          const block = section.querySelector(".section__title");
          const delay = sectionTarget ? `-=${delayAnimationDuration}` : null;
          sectionTL.fromTo(
            section.querySelector(".section__title"),
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
            delay
          );
          sectionTarget = true;
        }

        // section__description
        if (section.querySelector(".section__description")) {
          const delay = sectionTarget ? `-=${delayAnimationDuration}` : null;
          sectionTL.fromTo(
            section.querySelector(".section__description"),
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
            delay
          );
          sectionTarget = true;
        }

        // section__navigation
        if (section.querySelector(".section__navigation")) {
          const block = section.querySelector(".section__navigation");
          // .button.button_tertiary
          if (block.querySelector(".button.button_tertiary")) {
            const delay = sectionTarget ? `-=${delayAnimationDuration}` : null;
            sectionTL.fromTo(
              block.querySelector(".button.button_tertiary"),
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
              delay
            );
            sectionTarget = true;
          }
        }

        if (/*!section.classList.contains("contacts-item")
        && !section.classList.contains("image-slider")*/ !!true) {
          // swiper 
          if (section.querySelector(".swiper")) {
            section.querySelectorAll(".swiper > .swiper-wrapper > .swiper-slide").forEach(function (slide) {
              const delay = sectionTarget ? `-=${delayMediumAnimationDuration}` : null;
              sectionTL.fromTo(
                slide,
                { opacity: 0, y: 40 },
                { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "transform" },
                delay
              );
              sectionTarget = true;
            });
          }

          // section__swiper-nav
          if (section.querySelector(".section__swiper-nav")) {
            const block = section.querySelector(".section__swiper-nav");
            if (block.querySelector(".button")) {
              block.querySelectorAll(".button").forEach(function (button, index) {
                const x = index % 2 === 0 ? 20 : -20;
                const delay = sectionTarget ? `-=${delayMediumAnimationDuration}` : null;
                sectionTL.fromTo(
                  button,
                  { opacity: 0, x: x },
                  { opacity: 1, x: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
                  delay
                );
                sectionTarget = true;
              });
            }
          }

          // swiper__pagination
          if (section.querySelector(".swiper__pagination")) {
            const block = section.querySelector(".swiper__pagination");
            if (block.querySelector(".swiper__bullet")) {
              block.querySelectorAll(".swiper__bullet").forEach(function (bullet) {
                const delay = sectionTarget ? `-=${delayMediumAnimationDuration}` : null;
                sectionTL.fromTo(
                  bullet,
                  { opacity: 0, y: 10 },
                  { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
                  delay
                );
                sectionTarget = true;
              });
            }
          }
        }

        ScrollTrigger.create({
          animation: sectionTL,
          trigger: section,
          start: "top 80%",
          end: "bottom 100%",
          scrub: false,
        });
      }

      if (section.querySelector(".section__content > .button")) {
        const button = section.querySelector(".section__content > .button");
        const buttonClasses = Array.from(button.classList);

        if (buttonClasses.some(className => className.includes("__more"))) {
          const moreTL = gsap.timeline();
          moreTL.fromTo(
            button,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
          );

          ScrollTrigger.create({
            animation: moreTL,
            trigger: button,
            start: "top 80%",
            end: "bottom 100%",
            scrub: false,
          });
        }
      }
    });
  }
}
