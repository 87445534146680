document.addEventListener("DOMContentLoaded", () => {
  let containerOffset = window.getComputedStyle(document.querySelector(".container"), null);
  containerOffset = containerOffset.getPropertyValue('padding-right').trim();
  containerOffset = Number(containerOffset.replace("px", ""));

  gsap.registerPlugin(ScrollTrigger, CustomEase);

  CustomEase.create("cubicDefault", ".36,.3,0,1"); // cool animation
  CustomEase.create("cubicEIE", ".49,.66,0,1"); // ease-in-out

  function currentGSAP() {
    // all
    allHeaderFirstAnimation();
    allSectionFirstAnimation();
    allFooterFirstAnimation();
    allArticleFirstAnimation();
    allSectionWithCardsFirstAnimation(".category-section", ".category-card");
    allSectionWithCardsFirstAnimation(".advantages-small", ".advantages-small__item");
    allSectionWithCardsFirstAnimation(".scheme-section", ".scheme-section__card");

    LineByLineAnimation(".product-description", ".section__content > *");
    LineByLineAnimation(".for-participants", ".for-participants__column");
    LineByLineAnimation(".for-participants", ".for-participants__head");
    LineByLineAnimation(".for-participants", ".for-participants__item");

    // sections
    sectionFrontBannerFirstAnimation();
    sectionCTASectionFirstAnimation();
    sectionAboutSectionFirstAnimation();
    sectionPageTitleFirstAnimation();
    sectionGridSectionFirstAnimation();
    sectionCtaSidebarFirstAnimation();
    sectionFAQBlockFirstAnimation();
    sectionProductSectionFirstAnimation();
    sectionExcellenceSectionFirstAnimation();
    sectionComparisonSectionFirstAnimation();
    sectionBlogHeaderFirstAnimation();
    sectionArticleNavigationFirstAnimation();
    sectionErrorSectionFirstAnimation();
  }
  
  buttonAnimation();
  allBodyFirstAnimation(".page__body");

  currentGSAP();
});
