// show mega tab
function showMegaTab(name, section, activeClass = "js-mega-tab-active") {
  section.querySelectorAll("[data-mega-tab]").forEach(megaTab => {
    if (megaTab.getAttribute("data-mega-tab") === name) {
      megaTab.classList.add(activeClass);
      if (megaTab.getAttribute("data-mega-tab-card")) {
        megaTabCardAnimation(megaTab, megaTab.getAttribute("data-mega-tab-card"));
      }
    } else {
      megaTab.classList.remove(activeClass);
    };
  });
  document.querySelectorAll("[data-mega-tab-path]").forEach(megaTab => {
    const megaTabInput = megaTab.querySelector(".tab__input");
    megaTab.getAttribute("data-mega-tab-path") === name ? megaTabInput.checked = true : megaTabInput.checked = false;
  });
}

function megaTabCardAnimation(tab, selector) {
  const tabTL = gsap.timeline();
  tab.querySelectorAll(selector).forEach((card, index) => {
    const delay = index ? `-=${delayMediumAnimationDuration}` : null;
    tabTL.fromTo(
      card,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
      delay
    );
  });
}

function megaTabTarget(container, select = false) {
  const parent = container.closest(".section")
  if (!select) {
    const input = container.querySelector("input[type=\"radio\"]"),
      name = container.getAttribute("data-mega-tab-path");

    if (!parent) {
      alert("Ошибка: табы не в секции");
      return;
    }

    input.addEventListener("change", () => showMegaTab(name, parent));
  } else {
    const input = container, name = input.value;

    if (!parent) {
      alert("Ошибка: табы не в секции");
      return;
    }
    showMegaTab(name, parent);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("[data-mega-tab-path]")) {
    document.querySelectorAll("[data-mega-tab-path]").forEach(megaTabContainer => {
      megaTabTarget(megaTabContainer);
    });
  }

  if (document.querySelector("[data-mega-tab-select]")) {
    document.querySelectorAll("[data-mega-tab-select]").forEach(input => {
      input.addEventListener("change", () => {
        megaTabTarget(input, select = true);
      });
    });
  }
});
