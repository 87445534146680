document.addEventListener("DOMContentLoaded", function () {

  Fancybox.bind("[data-fancybox]", {
    placeFocusBack: false,
    Thumbs: false,
    Toolbar: {
      display: {
        left: [],
        middle: [],
        right: ["close"],
      },
    },
    Carousel: {
      infinite: false,
      transition: "slide",
    },
  });

  Fancybox.bind("[data-modal]", {
    autoFocus: false,
    placeFocusBack: false,
    on: {
      destroy: (fancybox) => {
        const mainModalTitleSelector = document.querySelector("#connect-with-us [data-modal-title]"),
          mainModalDescriptionSelector = document.querySelector("#connect-with-us [data-modal-description]"),
          mainModalButtonSelector = document.querySelector("#connect-with-us [data-modal-button]"),
          mainModalFormNameSelector = document.querySelector("#connect-with-us [data-modal-form-name]");
          
          mainModalTitleSelector.textContent = mainModalTitleSelector.getAttribute("data-modal-title");
          mainModalDescriptionSelector.textContent = mainModalDescriptionSelector.getAttribute("data-modal-description");
          mainModalButtonSelector.textContent = mainModalButtonSelector.getAttribute("data-modal-button");
          mainModalFormNameSelector.value = mainModalFormNameSelector.getAttribute("data-modal-form-name");
      },
    },
    // Это для отключения закрытия перетаскиванием
    /* dragToClose: false,
    Carousel: {
      Panzoom: {
        touch: false,
      },
    }, */
  });

  document.querySelectorAll("[data-modal]").forEach(button => {
    button.addEventListener("click", function () {
      Fancybox.close();
    });
  });
  
  const url = new URL(window.location.href);
  if (url.searchParams.get("solution") == "true") {
    Fancybox.show(
	  [{
	    src: "#selection-of-a-solution",
	  }], {
		Carousel: {
		  Panzoom: {
		 	touch: false,
		  },
		},
	  }
	);
  }
});


