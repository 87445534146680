function toCaseCount(number) {
  let titles = ['файл', 'файла', 'файлов'];
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)]];
}

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".file-input")) {

    function fileIsUpload(fileInputContainer, fileInputOldName) {
      const fileInput = fileInputContainer.querySelector("input[type=\"file\"]"),
        fileInputName = fileInputContainer.querySelector(".file-input__name");
      if (fileInput.files.length == 1) {
        fileInputName.innerHTML = fileInput.files[0].name.trim();
      } else if (fileInput.files.length > 1) {
        fileInputName.innerHTML = `${fileInput.files.length} ${toCaseCount(fileInput.files.length)}`;
      } else {
        fileInputName.innerHTML = fileInputOldName;
      }
    }

    document.querySelectorAll(".file-input").forEach(fileInputContainer => {
      const fileInput = fileInputContainer.querySelector("input[type=\"file\"]"),
        fileInputOldName = fileInputContainer.querySelector(".file-input__name").innerHTML.trim();
      fileInput.addEventListener("change", () => {
        fileIsUpload(fileInputContainer, fileInputOldName);
      });
    });

  }
});
