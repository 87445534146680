function sectionBlogHeaderFirstAnimation(selector = ".blog-header") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );
      
      // view-count 
      if (section.querySelector(".view-count")) {
        sectionTL.fromTo(
          section.querySelector(".view-count"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // blog-header__date
      if (section.querySelector(".blog-header__date")) {
        sectionTL.fromTo(
          section.querySelector(".blog-header__date"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // blog-header__tag
      if (section.querySelector(".blog-header__tag")) {
        sectionTL.fromTo(
          section.querySelector(".blog-header__tag"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // blog-header__share
      if (section.querySelector(".blog-header__share")) {
        sectionTL.fromTo(
          section.querySelector(".blog-header__share"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
          `-=${delayAnimationDuration}`
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
