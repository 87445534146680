document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector("[data-filter-select]")) {
    var breakpoint = 1250;
    
    if (window.innerWidth <= breakpoint) closeAllSelect();

    function closeAllSelect(item = undefined) {
      document.querySelectorAll("[data-filter-select]:not([data-filter-children])").forEach(function (select) {
        select.classList.remove('show');
      });
    }
  }
  
  document.querySelectorAll("[data-grid-section]").forEach(filter => {
    const filterCurrentList = filter.querySelectorAll("[data-filter-current]");
	
	filterCurrentList.forEach(current => {
	  const currentContainer = current.closest("[data-filter-select]");
		
	  current.addEventListener("click", event => {
		event.preventDefault();
		
		if (current.closest(".submenu-item__more")) {
		    current.closest("[data-filter-select]").classList.toggle("show");
		    return;
		}
		
		if (currentContainer.classList.contains("show") && window.innerWidth <= breakpoint) {
		    closeAllSelect();
		} else {
		    if (window.innerWidth <= breakpoint) {
    		    current.closest("[data-filter-select]").style.setProperty("--offset-left", current.closest("[data-filter-select]").offsetLeft * -1 + "px");
    		    closeAllSelect();
		    }
		    currentContainer.classList.toggle("show");
		}
	  });
	});
  });
  
  document.addEventListener("click", function (event) {
    if (!event.target.closest("[data-filter-select]") && window.innerWidth <= breakpoint) {
      closeAllSelect();
    }
  });

  // Проверяем количество фильтров
  // Если больше 1, то добавляем класс grid-section_grid
  if (document.querySelector("[data-grid-section]") && document.querySelector("[data-filter-select]")) {
    const section = document.querySelector("[data-grid-section]"),
      filters = section.querySelectorAll("[data-filter-select]");
    if (filters.length > 1) {
      section.classList.add("grid-section_grid");
    }
  }
});