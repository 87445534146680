document.addEventListener("click", event => {
    
    const rateButtomSelector = "[data-src='#rate']",
        rateModalTitleSelector = "#rate [data-modal-title]",
        rateModalDescriptionSelector = "#rate [data-modal-description]",
        rateModalProductInputSelector = "#rate input[name=productId]",
        rateModalRateInputSelector = "#rate input[name=rate]",
        rateModalArticleInputSelector = "#rate input[name=article]",
        rateModalPriceInputSelector = "#rate input[name=price]";
    
    const target = event.target;
    
    if (target.closest(rateButtomSelector)) {
        document.querySelector(rateModalTitleSelector).textContent = target.closest(rateButtomSelector).getAttribute("data-rate-form-pagetitle");
        document.querySelector(rateModalDescriptionSelector).textContent = target.closest(rateButtomSelector).getAttribute("data-rate-form-name");
        
        document.querySelector(rateModalProductInputSelector).value = target.closest(rateButtomSelector).getAttribute("data-rate-form-id");
        document.querySelector(rateModalRateInputSelector).value = target.closest(rateButtomSelector).getAttribute("data-rate-form-name");
        document.querySelector(rateModalArticleInputSelector).value = target.closest(rateButtomSelector).getAttribute("data-rate-form-article");
        document.querySelector(rateModalPriceInputSelector).value = target.closest(rateButtomSelector).getAttribute("data-rate-form-price");
    }
});