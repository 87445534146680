document.addEventListener("DOMContentLoaded", function () {

  function 标签折叠修复(屁股) {
    if (屁股.closest(".cta-sidebar"))
      屁股.closest(".cta-sidebar").style.width
        = `${屁股.closest(".cta-sidebar").offsetWidth}px`;
    // 🥴
  }

  function tabCollapseOpen(tab) {
    const text = tab.querySelector("[data-tab-text]"),
      container = tab.closest("[data-tab-collapse-container]");

    标签折叠修复(tab);

    if (text.querySelector("span").clientWidth) {
      gsap.to(text, {
        width: text.querySelector("span").clientWidth,
        duration: defaultAnimationDuration,
        ease: "cubicDefault"
      });
    }

    gsap.to(tab, {
      width: "100%",
      duration: defaultAnimationDuration,
      ease: "cubicDefault"
    });

    container.querySelectorAll("[data-tab-collapse]").forEach(function (item) {
      if (!item.querySelector(".tab__input").checked) {
        tabCollapseClose(item);
      }
    });
  }

  function tabCollapseClose(tab) {
    const content = tab.querySelector("[data-tab-content]"),
      text = tab.querySelector("[data-tab-text]");

    gsap.to(text, {
      width: 0,
      duration: defaultAnimationDuration,
      ease: "cubicDefault"
    });

    gsap.to(tab, {
      width: getComputedStyle(content).minWidth,
      duration: defaultAnimationDuration,
      ease: "cubicDefault"
    });
  }

  if (document.querySelector("[data-tab-collapse]")) {
    document.querySelectorAll("[data-tab-collapse]").forEach(function (tab) {

      tab.addEventListener("change", function (event) {
        if (tab.querySelector(".tab__input").checked) {
          tabCollapseOpen(tab);
        }
      });

      tabCollapseOpen(tab);
    });
  }
});
