const kordion = new Kordion("[data-kordion]", {
  speed: defaultAnimationDuration * 1000,
  spritePath: "assets/templates/img/sprite.svg",
  effect: "line-by-line",
  effectLineByLine: {
    speed: defaultAnimationDuration * 1000,
    delay: 40,
    x: 30
  }
});
