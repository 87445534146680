function sectionErrorSectionFirstAnimation(selector = ".error-section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // error-section__error
      if (section.querySelector(".error-section__error")) {
        sectionTL.fromTo(
          section.querySelector(".error-section__error"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // error-section__title 
      if (section.querySelector(".error-section__title")) {
        sectionTL.fromTo(
          section.querySelector(".error-section__title"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // error-section__description 
      if (section.querySelector(".error-section__description")) {
        sectionTL.fromTo(
          section.querySelector(".error-section__description"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // error-section__body .list li 
      if (section.querySelector(".error-section__body .list li")) {
        section.querySelectorAll(".error-section__body .list li").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
        });
      }

      // error-section__body > .button
      if (section.querySelector(".error-section__body > .button")) {
        sectionTL.fromTo(
          section.querySelector(".error-section__body > .button"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
