function sectionGridSectionFirstAnimation(selector = ".grid-section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      const sectionTL = gsap.timeline();
      const filterTL = gsap.timeline();
      const moreTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // grid-section__listing
      if (section.querySelector(".grid-section__listing")) {
        section.querySelectorAll(".grid-section__listing > div").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
        });
      }

      // .grid-filter
      if (section.querySelector(".grid-filter")) {
        section.querySelectorAll(".grid-filter").forEach(function (item) {
          filterTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
          );
          if (item.querySelector(".tab")) {
            item.querySelectorAll(".tab").forEach(function (tab) {
              filterTL.fromTo(
                tab,
                { opacity: 0, y: 20 },
                { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
                `-=${delayLongAnimationDuration}`
              );
            });
          }
        });
      }

      // grid-section__more
      if (section.querySelector(".grid-section__more")) {
        section.querySelectorAll(".grid-section__more").forEach(function (item) {
          moreTL.fromTo(
            item,
            { opacity: 0, y: 40 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" }
          );
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });

      ScrollTrigger.create({
        animation: filterTL,
        trigger: section.querySelector(".grid-filter"),
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });

      ScrollTrigger.create({
        animation: moreTL,
        trigger: section.querySelector(".grid-section__more"),
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
