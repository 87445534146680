
// ╭─────────────────ЗаметОчка──────────────────╮
// │                                            │
// │  Костыль для навигации с переполнением     │
// │                                            │
// │  ╭╮╯╰(　-_･) ︻デ═一  ▸          c( O.O )ɔ  │
// │                                            │
// ╰────────────────────────────────────────────╯

if (document.querySelector("[data-nav-overflow-container]") && window.innerWidth > 1250) {
  let startWindowSize = window.innerWidth;

  function navOverflowLeave(container) {
    container.classList.remove("js-nav-overflow-detect");
    container.style.overflow = "";
  }

  function navOverflowEnter(container) {
    container.classList.add("js-nav-overflow-detect");
    container.style.overflow = "auto";
  }

  function navOverflowWhile(container, items, overflowList, index = 1) {
    while (getScroll('Width', container)) {
      const item = items[items.length - index],
        newItem = document.createElement("span"),
        cloneItem = item.cloneNode(true);

      item.style.display = "none";
      cloneItem.style.display = "";
      newItem.classList.add("select-answer__item");
      newItem.appendChild(cloneItem);
      overflowList.prepend(newItem);
      index++;
    }
  }

  function navOverflowProcess(container/*📦*/, items, overflowItem, overflowList) {
    navOverflowEnter(container);

    items.forEach(function (item) {
      item.style.display = "";
    });
    
    overflowItem.style.display = "";
    overflowList.innerHTML = null;

    navOverflowWhile(container, items, overflowList, index = 1);

    if (!overflowList.querySelector("span")) {
      overflowItem.style.display = "none";
    }

    navOverflowLeave(container);
  }

  document.querySelectorAll("[data-nav-overflow-container]").forEach(function (container) {
    const nav = container?.querySelector("[data-nav-overflow]"),
      navItems = nav?.querySelectorAll("[data-nav-item]"),
      navOverflowItem = nav?.querySelector("[data-nav-overflow-item]"),
      navOverflowList = nav?.querySelector("[data-nav-overflow-list]");

    if (getScroll('Width', container)) {
      navOverflowProcess(container, navItems, navOverflowItem, navOverflowList);
    } else {
      navOverflowLeave(container);
    }

    // resize
    let navOverflowTimeout;
    window.addEventListener("resize", function () {
      clearTimeout(navOverflowTimeout);
      navOverflowTimeout = setTimeout(function () {
        navOverflowProcess(container, navItems, navOverflowItem, navOverflowList);
      }, 100);
    });
  });
} else if (window.innerWidth <= 1250) {
  document.querySelectorAll(".js-nav-overflow-detect").forEach(function (item) {
    item.classList.remove("js-nav-overflow-detect");
    item.style.overflow = "";
  });
}
