function allArticleFirstAnimation(selector = ".article") {
  if (document.querySelector(selector)) {

    document.querySelectorAll(`${selector} > *`).forEach(function (item) {
      sectionTL = gsap.timeline();

      sectionTL.fromTo(
        item,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: mediumAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: item,
        start: "top 95%",
        end: "bottom 100%",
        scrub: false,
      });

    });

  }
}
