function sectionCtaSidebarFirstAnimation(selector = ".cta-sidebar") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // .cta-sidebar__title
      if (section.querySelector(".cta-sidebar__title")) {
        sectionTL.fromTo(
          section.querySelector(".cta-sidebar__title"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
          `-=${delayAnimationDuration}`
        );
      }

      // cta-sidebar__image
      if (section.querySelector(".cta-sidebar__image")) {
        sectionTL.fromTo(
          section.querySelector(".cta-sidebar__image"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // .cta-sidebar__name
      if (section.querySelector(".cta-sidebar__name")) {
        sectionTL.fromTo(
          section.querySelector(".cta-sidebar__name"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // .cta-sidebar__connect
      if (section.querySelector(".cta-sidebar__connect")) {
        sectionTL.fromTo(
          section.querySelector(".cta-sidebar__connect"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // .cta-sidebar__social > .button
      if (section.querySelector(".cta-sidebar__social > .button")) {
        section.querySelectorAll(".cta-sidebar__social > .button").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
        });
      }

      // .text-input
      if (section.querySelector(".text-input")) {
        section.querySelectorAll(".text-input").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "transform" },
            `-=${delayAnimationDuration}`
          );
        });
      }

      // cta-sidebar__privacy 
      if (section.querySelector(".cta-sidebar__privacy")) {
        sectionTL.fromTo(
          section.querySelector(".cta-sidebar__privacy"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // cta-sidebar__submit
      if (section.querySelector(".cta-sidebar__submit")) {
        sectionTL.fromTo(
          section.querySelector(".cta-sidebar__submit"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
