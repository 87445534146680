function headerBackgroundAdd() {
  document.querySelector(".header").classList.add("header_background");
}

function headerBackgroundRemove() {
  document.querySelector(".header").classList.remove("header_background");
}

function headerBackgroundChange() {
  const header = document.querySelector(".header"),
    headerRect = header.getBoundingClientRect(),
    headerBottom = headerRect.bottom,
    sectionUnderHeader = document.elementFromPoint((headerRect.right - headerRect.left) / 2, headerBottom + 5);

  sectionUnderHeader.classList.contains("header-transparent") ? headerBackgroundRemove() : headerBackgroundAdd();
}

document.addEventListener("DOMContentLoaded", function () {
  const header = document.querySelector("header.header");
  
  window.addEventListener("scroll", function () {
    headerBackgroundChange();
    scrollY && header.classList.contains("header_background")
      ? header.classList.add("header_shadow")
      : header.classList.remove("header_shadow");

    if (window.innerWidth <= 1250 && document.querySelector(".product-scroll__hidden_active")) {
      header.classList.add("header_background");
      header.classList.remove("header_shadow");
    }
  });

  window.addEventListener("resize", function () {
    document.querySelector("html").style.setProperty("--global-header-height", header.clientHeight + "px");
  });

  headerBackgroundChange();
  document.querySelector("html").style.setProperty("--global-header-height", header.clientHeight + "px");
});
