function sectionFrontBannerFirstAnimation(selector = ".front-banner") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      // .front-banner__icon
      if (section.querySelector(".front-banner__icon")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner__icon"),
          { opacity: 0, scale: 0.5 },
          { opacity: 1, scale: 1, duration: 1.7, ease: "cubicDefault" }
        );
      }

      // .front-banner__gradient
      if (section.querySelector(".front-banner__gradient")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner__gradient"),
          {
            opacity: 0,
            "--gradient-image": `radial-gradient(138% 33% at -100% -100%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%), 
            radial-gradient(96% 51% at -100% -100%, rgba(250, 204, 214, 1) 0%, rgba(250, 204, 214, 0) 100%), 
            linear-gradient(0deg, rgba(0, 0, 0, 0) -100%, rgba(255, 255, 255, 0.0) -300%, rgba(0, 0, 0, 0) -600.69%),
            radial-gradient(212% 83% at -100% -100%, #8A9BCB -100%, rgba(222, 228, 242, 0) 100%),
            radial-gradient(60% 53% at -100% -100%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
            linear-gradient(0deg, #8FAFD2 -100%, #E4E6F3 49%, #899BCD 200%), #FFF`,
          },
          {
            opacity: 1,
            "--gradient-image": `radial-gradient(138% 33% at 76% -4%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%), 
            radial-gradient(96% 51% at 150% 24%, rgba(250, 204, 214, 1) 0%, rgba(250, 204, 214, 0) 100%), 
            linear-gradient(332deg, rgba(0, 0, 0, 0) 25%, rgba(255, 255, 255, 0.35) 55%, rgba(0, 0, 0, 0) 90.69%),
            radial-gradient(212% 83% at 100% 100%, #8A9BCB 0%, rgba(222, 228, 242, 0) 100%),
            radial-gradient(60% 53% at 72% 0%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
            linear-gradient(106deg, #8FAFD2 0%, #E4E6F3 49%, #899BCD 99%), #FFF`,
            duration: 5,
            ease: "cubicDefault",
            onComplete: frontBannerPostAnimation,
          }, "-=1.7"
        );
      }

      // .front-banner__title
      if (section.querySelector(".front-banner__title")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner__title"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          "-=3"
        );
      }

      // .front-banner__description
      if (section.querySelector(".front-banner__description")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner__description"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          "-=2.75"
        );
      }

      // .front-banner-form label
      if (section.querySelector(".front-banner-form label")) {
        section.querySelectorAll(".front-banner-form label").forEach(function (item, index) {
          let delay = `-=${2.5 - delayAnimationDuration * index}`;
          if (index === 2) {
            delay = "-=1.75";
          }

          sectionTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "transform" },
            delay
          );
        });
      }

      // .front-banner-form .button
      if (section.querySelector(".front-banner-form .button")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner-form .button"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          "-=2"
        );
      }

      // .front-banner-advantages__item
      if (section.querySelector(".front-banner-advantages__item")) {
        section.querySelectorAll(".front-banner-advantages__item").forEach(function (item, index) {
          const y = index % 2 === 0 ? 20 : -20;
          sectionTL.fromTo(
            item,
            { opacity: 0, y: y },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${1.5 - delayAnimationDuration * index}`
          );
        });
      }

      // .front-banner-payments__sum
      if (section.querySelector(".front-banner-payments__sum")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner-payments__sum"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          "-=1.5"
        );
      }

      // .front-banner-payments__text
      if (section.querySelector(".front-banner-payments__text")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner-payments__text"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          "-=1.25"
        );
      }

      // .front-banner-preview
      if (section.querySelector(".front-banner-preview")) {
        sectionTL.fromTo(
          section.querySelector(".front-banner-preview"),
          { opacity: 0, scale: 1.1 },
          { opacity: 1, scale: 1, duration: 1, ease: "cubicDefault" },
          "-=1.5"
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });
    });
  }
}

function frontBannerPostAnimation() {
  gsap.fromTo(
    ".front-banner__gradient",
    {
      "--gradient-image": `radial-gradient(138% 33% at 76% -4%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%), 
        radial-gradient(96% 51% at 150% 24%, rgba(250, 204, 214, 1) 0%, rgba(250, 204, 214, 0) 100%), 
        linear-gradient(332deg, rgba(0, 0, 0, 0) 25%, rgba(255, 255, 255, 0.35) 55%, rgba(0, 0, 0, 0) 90.69%),
        radial-gradient(212% 83% at 100% 100%, #8A9BCB 0%, rgba(222, 228, 242, 0) 100%),
        radial-gradient(60% 53% at 72% 0%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
        linear-gradient(106deg, #8FAFD2 0%, #E4E6F3 49%, #899BCD 99%), #FFF` },
    {
      "--gradient-image": `radial-gradient(50% 50% at 120% 60%, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0) 100%), 
        radial-gradient(96% 51% at 20% 100%, rgba(250, 204, 214, 0.5) 0%, rgba(0, 0, 0, 0) 100%), 
        linear-gradient(432deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.35) 55%, rgba(0, 0, 0, 0) 90.69%),
        radial-gradient(212% 83% at 0% 100%, #8A9BCB 0%, rgba(222, 228, 242, 0) 100%),
        radial-gradient(60% 53% at 72% 0%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
        linear-gradient(106deg, #8FAFD2 0%, #E4E6F3 60%, #899BCD 130%), #FFF`, duration: 7, repeat: -1, yoyo: true
    },
  );

  const section = document.querySelector(".front-banner")

  document.addEventListener("mousemove", function (event) {
    const x = event.clientX / window.innerWidth - 0.5;
    const y = event.clientY / window.innerHeight - 0.5;

    gsap.to(
      ".front-banner__icon",
      { x: x * 70, y: y * 70, rotate: x * 10, duration: 2 }
    );

    gsap.to(
      ".front-banner-preview__image img",
      { x: x * 10, y: y * 10, rotate: x * 2, duration: 1.5 }
    );

    section.querySelectorAll(".front-banner-preview__adv").forEach(function (adv, index) {
      const minus = index % 2 === 0 ? -1 : 1;
      gsap.to(
        adv,
        { x: x * 20 * (index + 1), y: y * 20 * (index + 1), rotate: x * 7 * minus, duration: 1.9 }
      );
    });
  });
}
