function sectionAboutSectionFirstAnimation(selector = ".about-section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      // about-section__image
      if (section.querySelector(".about-section__image")) {
        sectionTL.fromTo(
          section.querySelector(".about-section__image"),
          { opacity: 0 },
          { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
        );
      }

      // about-section__image img
      if (section.querySelector(".about-section__image img")) {
        sectionTL.fromTo(
          section.querySelector(".about-section__image img"),
          { scale: 1.2 },
          { scale: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
          `-=${delayAnimationDuration}`
        );
      }

      // .about-advantages
      if (section.querySelector(".about-advantages")) {
        section.querySelectorAll(".about-advantages").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
