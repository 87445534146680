function sectionPageTitleFirstAnimation(selector = ".page-title") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      // page-title__title
      if (section.querySelector(".page-title__title")) {
        sectionTL.fromTo(
          section.querySelector(".page-title__title"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
        );
      }

      // page-title__description
      if (section.querySelector(".page-title__description")) {
        sectionTL.fromTo(
          section.querySelector(".page-title__description"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
          `-=${delayAnimationDuration}`
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
