var mobileBreakPoints = 600,
  mediumAnimationDuration = 0.625,
  defaultAnimationDuration = 0.5,
  delayAnimationDuration = 0.25,
  delayMediumAnimationDuration = 0.31,
  delayLongAnimationDuration = 0.4,
  delayShortAnimationDuration = 0.17;


// ╭─────────────────ЗаметОчка──────────────────╮
// │                                            │
// │        Проверка на ширину скроллбара       │
// │                                            │
// ╰────────────────────────────────────────────╯
function getScrollBarWidth() {
  let a, b;
  document.body.style.setProperty("overflow", "auto");
  a = startSize = document.body.clientWidth;
  document.body.style.setProperty("overflow", "hidden");
  b = endSize = document.body.clientWidth;
  document.body.style.removeProperty("overflow");
  return b - a;
}

// ╭─────────────────ЗаметОчка──────────────────╮
// │                                            │
// │       true/false на наличие скролла        │
// │                                            │
// ╰────────────────────────────────────────────╯
function getScroll(a/**/, e = d.documentElement, d/*📄*/ = document, b/*🤷‍♂️*/ = d.body,) {
  return /CSS/.test(d.compatMode) ? (e["client" + a] < e["scroll" + a]) : (b["client" + a] < b[a])
};

document.addEventListener("DOMContentLoaded", function () {
  function scrollToElement(element) {
    let elemCoords;
    let top;

    if (!element) {
      top = 0;
    } else {
      elemCoords = element.getBoundingClientRect();
      top = elemCoords.top + pageYOffset - document.querySelector('.header').offsetHeight;
    }
    window.scrollTo({
      top: top,
      behavior: "smooth"
    });
  };

  document.addEventListener('click', function (event) {
    const target = event.target;

    if (target.closest('[data-scroll-to]')) {
      event.preventDefault()

      let elemHref = target.closest('[data-scroll-to]').href;
      let parseHref = elemHref.substr(elemHref.indexOf("#") + 1);

      scrollToElement(document.querySelector(`#${parseHref}`));
    }
  });
});

$(document).on('mse2_load', function (e, data) {
  ScrollTrigger.refresh();
  $('body').msfavorites('loadFavorites', true);
  buttonAnimation();
});

$(document).on('pdopage_load', function (e, config, response) {
  ScrollTrigger.refresh();
  $('body').msfavorites('loadFavorites', true);
  buttonAnimation();
});
