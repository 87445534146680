document.addEventListener("DOMContentLoaded", () => {
    const reviewModalContent = document.querySelector(".review-modal");
    
    document.addEventListener("click", event => {
        const target = event.target;
        
        if (target.closest(".review-card__more")) {
            event.preventDefault();
            
            reviewModalContent.innerHTML = "";
            reviewModalContent.append(target.closest(".review-card").cloneNode(true));
            Fancybox.show(
                [{
                    src: "#dialog-review",
                }]
            );
        }
    });
});