const notyf = new Notyf({
    ripple: false,
    position: {
        x: 'right',
        y: 'top'
    },
    types: [
    {
        type: 'success',
        background: 'var(--system-green)',
        className: 'text_body-l_m',
    },
    {
      type: 'error',
      background: 'var(--system-red)',
      className: 'text_body-l_m',
    },
    {
      type: 'info',
      background: 'var(--system-yellow)',
      className: 'text_body-l_m',
    },
    {
      type: 'favoritesAdd',
      background: 'var(--accent-secondary-default)',
      className: 'text_body-l_m',
      icon: '<i class="notyf__icon--success" style="color: var(--accent-secondary-default);"></i>',
    },
    {
      type: 'favoritesRemove',
      background: 'var(--accent-secondary-default)',
      className: 'text_body-l_m',
      icon: '<i class="notyf__icon--error" style="color: var(--accent-secondary-default);"></i>',
    }
  ]
});

if (typeof FetchIt !== "undefined") {
    FetchIt.Message = {
        success(message) {
            notyf.success(message);
        },
        error(message) {
            notyf.error(message);
        },
    };
}
