function allFooterFirstAnimation(selector = ".footer") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      // footer
      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // footer__logo
      if (section.querySelector(".logo")) {
        sectionTL.fromTo(
          section.querySelector(".logo"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
          `-=${delayMediumAnimationDuration}`
        );
      }

      // footer__nav
      if (section.querySelector(".footer__nav")) {
        section.querySelectorAll(".footer__nav").forEach(function (nav) {
          sectionTL.fromTo(
            nav,
            { opacity: 0 },
            { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
            `-=${delayMediumAnimationDuration}`
          );
        });
      }

      // footer__bottom
      if (section.querySelector(".footer__bottom")) {
        sectionTL.fromTo(
          section.querySelector(".footer__bottom"),
          { opacity: 0 },
          { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
          `-=${delayMediumAnimationDuration}`
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
