function sectionFAQBlockFirstAnimation(selector = ".faq-block") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // .faq-block__item
      if (section.querySelector(".faq-block__item")) {
        section.querySelectorAll(".faq-block__item").forEach(function (item) {
          if (item.classList.contains("js-kordion-active")) {
            sectionTL.fromTo(
              item,
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${delayAnimationDuration}`
            );
            sectionTL.fromTo(
              item.querySelector(".faq-block__head"),
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" },
              `-=${delayAnimationDuration}`
            );
            sectionTL.fromTo(
              item.querySelector(".faq-block__text"),
              { opacity: 0, y: -20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${defaultAnimationDuration}`
            );
          } else {
            sectionTL.fromTo(
              item,
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${delayAnimationDuration}`
            );
          }
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
