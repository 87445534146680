function buttonAnimation(selector = "[data-button-animation]") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (button) {

      ["mouseenter", "mouseout", "mousedown", "mouseup"].forEach(function (event) {
        button.addEventListener(event, function (e) {
          const parentOffset = button.getBoundingClientRect(),
            relY = e.clientY - parentOffset.top,
            relX = e.clientX - parentOffset.left;
          gsap.set(
            button,
            {
              "--before-top": relY + "px",
              "--before-left": relX + "px",
            }
          );
          if (event == "mouseenter") {
            gsap.to(
              button,
              {
                "--before-scale": 3,
                "--before-size": "300%",
                duration: mediumAnimationDuration,
                ease: "cubicDefault"
              }
            );
          } else if (event == "mouseout") {
            gsap.to(
              button,
              {
                "--before-scale": 0,
                "--before-size": "0%",
                duration: mediumAnimationDuration,
                ease: "cubicDefault"
              },
            );
          }
        });
      });

    });
  }
};
