function allSectionWithCardsFirstAnimation(selector = ".footer", card) {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();
      if (!!card && section.querySelector(card)) {
        section.querySelectorAll(card).forEach(function (card, index) {
          const delay = index ? `-=${delayMediumAnimationDuration}` : null;
          sectionTL.fromTo(
            card,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "transform" },
            delay
          );
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
