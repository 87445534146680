function allHeaderFirstAnimation(selector = ".header.js-animation") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      gsap.fromTo(
        section,
        { y: "-100%" },
        { y: 0, duration: defaultAnimationDuration, ease: "cubicDefault", delay: defaultAnimationDuration }
      );
    });
  }
}
