function sectionExcellenceSectionFirstAnimation(selector = ".excellence-section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // .excellence-section__card
      if (section.querySelector(".excellence-section__card")) {
        section.querySelectorAll(".excellence-section__card").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 40 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
          // excellence-section__title
          if (item.querySelector(".excellence-section__title")) {
            sectionTL.fromTo(
              item.querySelector(".excellence-section__title"),
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${delayMediumAnimationDuration}`
            );
          }
          // excellence-section__description
          if (item.querySelector(".excellence-section__description")) {
            sectionTL.fromTo(
              item.querySelector(".excellence-section__description"),
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${delayMediumAnimationDuration}`
            );
          }
          // excellence-section__image
          if (item.querySelector(".excellence-section__image")) {
            sectionTL.fromTo(
              item.querySelector(".excellence-section__image"),
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${delayMediumAnimationDuration}`
            );
          }

        });
      }
      

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });

    });
  }
}
