setTimeout(function(){
    document.querySelectorAll('input[name="message-key"]').forEach(item => {
       item.value = item.getAttribute('data-value');
    });
}, 4000);

document.addEventListener('fetchit:after', (event) => {
    const { response, form } = event.detail;

    if (form.getAttribute('data-msop-form')) {
        const disabledOptions = ['html', 'cms', 'hosting'];
        setTimeout(() => {
            disabledOptions.forEach(option => {
                form.querySelector(`input[name="options[${option}]"]`).disabled = true;
            });
        }, 100);
    }
    
    setTimeout(function(){
        const formErrorInputs = [...form.querySelectorAll(".common-input_error")];
        
        formErrorInputs.forEach(input => {
            input.classList.remove("common-input_error");
        })
    }, 2000);
});

document.addEventListener('fetchit:success', (event) => {
    const { response, form } = event.detail;
    
    Fancybox.close();
});

document.addEventListener('fetchit:reset', (event) => {
    const { response, form } = event.detail;
    
    const messageKey = form.querySelector('input[name="message-key"]');
    
    setTimeout(() => {
        messageKey.value = messageKey.getAttribute('data-value');
    }, 1000);
});