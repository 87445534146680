function sectionProductSectionFirstAnimation(selector = ".product-section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      const sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // product-section__image
      if (section.querySelector(".product-section__image")) {
        sectionTL.fromTo(
          section.querySelector(".product-section__image"),
          { opacity: 0 },
          { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // product-section__image > .zoom-image
      if (section.querySelector(".product-section__image > .zoom-image")) {
        section.querySelectorAll(".product-section__image > .zoom-image").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0 },
            { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
          sectionTL.fromTo(
            item.querySelector("img"),
            { scale: 1.1 },
            { scale: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${defaultAnimationDuration}`
          );
        });
      }

      // .product-form
      if (section.querySelector(".product-form")) {
        sectionTL.fromTo(
          section.querySelector(".product-form"),
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // product-options
      if (section.querySelector(".product-options")) {
        section.querySelectorAll(".product-options").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 40 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
          if (item.querySelector(".product-options__banner")) {
            sectionTL.fromTo(
              item.querySelector(".product-options__banner"),
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
              `-=${delayAnimationDuration}`
            );
          }
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });
    });
  }
}
