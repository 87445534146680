document.addEventListener('input', event => {
    const target = event.target;

    if (target.matches('input[name="name"]') || target.matches('input[name="receiver"]')) {
        target.value = target.value.replace(/[^A-Za-zА-ЯЁа-яё\-\s]/g, '');
    }
    
    if (target.matches('input[type="tel"]')) {
        target.value = target.value.replace(/[^0-9+\(\)\.\-\s]/g, '');
    }
});

document.addEventListener('change', event => {
    const target = event.target;

    if (target.matches('input[name="competitors"]')) {
        let tempValue = target.value.split(/(?:,| )+/); 
        tempValue.forEach((item, index) => {
            tempValue[index] = item.replace(/(^\w+:|^)\/\//g, '');
        });
        target.value = tempValue.join(', ');
    }
});