document.addEventListener("DOMContentLoaded", function () {

  function tooltipShow(element) {
    element.classList.add("tooltip_active");
  }

  function tooltipHide(element) {
    element.classList.remove("tooltip_active");
    element.querySelector(".tooltip__button").classList.remove("js-tooltip-clicked");
  }

  function tooltipHideAll() {
    document.querySelectorAll(".tooltip_active").forEach(function (element) {
      element.classList.remove("tooltip_active");
      element.querySelector(".tooltip__button").classList.remove("js-tooltip-clicked");
    });
  }

  document.querySelectorAll(".tooltip").forEach(function (tooltipContainer) {
    const tooltipButton = tooltipContainer.querySelector(".tooltip__button"),
      tooltipText = tooltipContainer.querySelector(".tooltip__text");

    Popper.createPopper(tooltipButton, tooltipText, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 7],
          },
        },
        {
          name: 'flip',
          options: {
            padding: 100,
          },
        },
      ],
    });

    tooltipButton.addEventListener("mouseenter", function () {
      tooltipShow(tooltipContainer);
    });

    tooltipButton.addEventListener("mouseleave", function () {
      if (!tooltipButton.classList.contains("js-tooltip-clicked")) {
        tooltipHide(tooltipContainer);
      }
    });

    tooltipButton.addEventListener("click", function (event) {
      event.preventDefault();
      tooltipHideAll();
      tooltipShow(tooltipContainer);
      tooltipButton.classList.toggle("js-tooltip-clicked");
    });

    document.addEventListener("click", function (event) {
      if (!event.target.closest(".tooltip")) {
        tooltipHideAll();
      }
    });
  });
});
