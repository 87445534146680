function sectionArticleNavigationFirstAnimation(selector = ".article-navigation") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      const sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0 },
        { opacity: 1, duration: defaultAnimationDuration, ease: "cubicDefault", clearProps: "all" }
      );

      // article-navigation__image img
      if (section.querySelector(".article-navigation__image img")) {
        sectionTL.fromTo(
          section.querySelector(".article-navigation__image img"),
          { scale: 1.1 },
          { scale: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // article-navigation__title 
      if (section.querySelector(".article-navigation__title")) {
        sectionTL.fromTo(
          section.querySelector(".article-navigation__title"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${delayAnimationDuration}`
        );
      }

      // article-navigation__item
      if (section.querySelector(".article-navigation__item")) {
        section.querySelectorAll(".article-navigation__item").forEach(function (item) {
          sectionTL.fromTo(
            item,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${delayAnimationDuration}`
          );
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        scrub: false,
      });

    });
  }
}
