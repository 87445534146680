document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector(".article table")) {
    document.querySelectorAll(".article table").forEach(function (table) {
      const wrapper = document.createElement("div");
      wrapper.classList.add("table-responsive");
      table.parentNode.insertBefore(wrapper, table);
      wrapper.appendChild(table);
    });
  }
});
