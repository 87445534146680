/*async function currencyCalc(price) {
    const request = { price: price, action: 'currencyCalc' };
            
    const response = await fetch('/assets/elements/ajax/currencyCalc.php', {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json();
    
    return result;
}

function getMsopData(msopName, msopData) {
    if (msopData.name == msopName) {
        const msopNameElems = document.querySelectorAll("[data-msop-name]"),
            msopPriceElems = document.querySelectorAll("[data-msop-price]"),
            msopOldPriceElems = document.querySelectorAll("[data-msop-old-price]"),
            msopDaysElem = document.querySelector("[data-msop-days]"),
            msopInputNameElem = document.querySelector("[data-msop-name-input]"),
            msopInputPriceElem = document.querySelector("[data-msop-price-input]"),
            rateElem = document.querySelector(`[data-rate-id="${msopData.id}"]`),
            rateDays = rateElem.getAttribute("data-rate-days");
        
        msopNameElems.forEach(item => {
            item.textContent = msopData.name;
        });
        
        currencyCalc(msopData.price).then(result => {
            msopPriceElems.forEach(item => {
                item.textContent =  result;
            });
        });
        
        if (msopData.old_price) {
            currencyCalc(msopData.old_price).then(result => {
                msopOldPriceElems.forEach(item => {
                    item.textContent = result;
                });
            });
        }
        
        msopOldPriceElems.forEach(item => {
            if (msopData.old_price) {
                item.closest("del").style.display = "block";
            } else {
                item.closest("del").style.display = "none";
            }
        });
        
        msopDaysElem.textContent = rateDays;
        msopInputNameElem.value = msopData.name;
        msopInputPriceElem.value = miniShop2.Utils.formatPrice(msopData.price);
    }
}

$(document).on('msoptionsprice_product_action', function (event, action, form, result) {
    if (action == 'modification/get' && result.success && result.data) {
        const msopOptions = result.data.options,
            msopModifications = result.data.modifications;
            
        if (msopOptions["integration"]) {
            msopModifications.forEach(item => {
                getMsopData("Диджитал Маэстро", item);
            });
            return;
        }
        
        if (msopOptions["install"] || msopOptions["filling"]) {
            msopModifications.forEach(item => {
                getMsopData("Веб-Ниндзя", item);
            });
            return;
        }

        msopModifications.forEach(item => {
            getMsopData("Мастер Код", item);
        });
    }
});*/