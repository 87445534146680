document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("[data-select-input]")) {

    function closeAllSelect() {
      document.querySelectorAll("[data-select-input]").forEach(select => {
        select.querySelector(".select-input__checkbox").checked = false;
      });
    }

    document.querySelectorAll("[data-select-input]").forEach(select => {

      const selectCheckbox = select.querySelector(".select-input__checkbox"),
        selectCurrent = select.querySelector(".select-current"),
        selectCurrentText = select.querySelector(".select-current__text"),
        selectAnswer = select.querySelector(".select-input__input"),
        selectAnswerList = select.querySelectorAll(".select-answer__item");

      selectCurrent.addEventListener("click", event => {
        if (!selectCheckbox.disabled) {
          event.preventDefault();
          const checkboxIsChecked = selectCheckbox.checked;
          closeAllSelect();
          if (!checkboxIsChecked) {
            selectCheckbox.checked = true;
          }
        }
      });

      if (!select.classList.contains("select-input_checkbox")) {
        if (select.getAttribute("data-select-input") != "ignore-click") {
          selectAnswerList.forEach(answer => {
            answer.addEventListener("click", event => {
              if (answer.tagName.trim().toLowerCase() != "a") {
                event.preventDefault();
              }
              let answerText = answer.querySelector(".select-answer__text");
              answerText = answerText.innerHTML.trim();
              if (answer.getAttribute("data-select-value")) {
                selectAnswer.value = answer.getAttribute("data-select-value");
                selectAnswer.dispatchEvent(new Event('change'));
              } else {
                selectAnswer.value = answerText;
                selectAnswer.dispatchEvent(new Event('change'));
              }
              selectCurrentText.innerHTML = answerText;
              if (selectCurrentText.classList.contains("placeholder")) {
                selectCurrentText.classList.remove("placeholder");
              }
              closeAllSelect();
            });
          });
        }
      } else {
        selectAnswerList.forEach(answer => {
          if (select.getAttribute("data-select-input") != "ignore-click") {
            answer.addEventListener("click", () => {
              var answerArray = [];
              var answerText = "";
              selectCurrentText.classList.remove("placeholder");
              selectAnswerList.forEach(value => {
                const valueCheckbox = value.querySelector(".select-answer__input"),
                  valueAnswer = valueCheckbox.closest(".select-answer__item").querySelector(".select-answer__text");

                if (valueCheckbox.checked) {
                  answerArray.push(valueAnswer.innerHTML.trim());
                }
              });

              answerArray.forEach((answerItem, index) => {
                index != answerArray.length - 1 ? answerText += answerItem + ", " : answerText += answerItem;
              });

              selectCurrentText.innerHTML = answerText;
              selectAnswer.value = answerText;
            });
          }
        });
      }

    });

    document.addEventListener("click", (event) => {
      if (!event.target.closest("[data-select-input]")) {
        closeAllSelect();
      }
    });
  }
});
