document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".text-input_datetime")) {
    document.querySelectorAll(".text-input_datetime").forEach(datetimeContainer => {
      const datetimeInput = datetimeContainer.querySelector(".text-input__input"),
        datetimeCurrent = datetimeContainer.querySelector(".text-input__current-text");

      datetimeInput.addEventListener("change", () => {
        if (datetimeInput.value) {
          const dateString = datetimeInput.value,
            date = new Date(dateString),
            formattedDate = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ', ' + date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2);

          datetimeContainer.classList.add("value");
          datetimeCurrent.innerHTML = formattedDate;
        } else {
          datetimeContainer.classList.remove("value");
        }
      });
    });
  }
});
