document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector("[data-search]")
    && document.querySelector("[data-search-modal]")) {
    const modal = document.querySelector("[data-search-modal]"),
      modalClose = modal.querySelector(".search-modal__close"),
      modalInput = modal.querySelector("input[type=\"text\""),
      modalForm = modal.querySelector("form"),
      modalInputMinlength = modalInput.getAttribute("minlength"),
      modalResults = modal.querySelector(".search-modal__result"),
      backdrop = document.querySelector(".elite-backdrop");
      
    document.querySelectorAll("[data-search]").forEach(function (button) {
      button.addEventListener("click", function (event) {
        event.preventDefault();
        modal.classList.add("search-modal_show");
        backdrop.classList.add("elite-backdrop_show");
        setTimeout(() => {
          modalInput.focus();
        }, 500);
      });
    });

    modalClose.addEventListener("click", function (event) {
      event.preventDefault();
      modal.classList.remove("search-modal_show");
      backdrop.classList.remove("elite-backdrop_show");
    });
    
    modalInput.addEventListener("input", function (event) {
        if (this.value.length < modalInputMinlength ) {
            modalResults.innerHTML = "";
        }
    });
    
    modalForm.addEventListener("reset", function (event) {
        modalResults.innerHTML = "";
    });

    backdrop.addEventListener("click", function (event) {
      event.preventDefault();
      modal.classList.remove("search-modal_show");
      backdrop.classList.remove("elite-backdrop_show");
    });
    
    if (!isMobile.any()) {
      window.addEventListener("scroll", function () {
        modal.classList.remove("search-modal_show");
        backdrop.classList.remove("elite-backdrop_show");
      }); 
    }
    
    window.addEventListener("keydown", function (event) {
      if(event.keyCode === 27) {
        modal.classList.remove("search-modal_show");
        backdrop.classList.remove("elite-backdrop_show");
      }
    });
  }
});
