document.addEventListener("DOMContentLoaded", () => {
    const mFilter2Groups = document.querySelectorAll("[data-mfilter2-group]"),
        mFilter2NumberFilters = document.querySelectorAll("[data-mfilter2-number-filter]");
    
    mFilter2Groups.forEach(item => {
        const mFilter2GroupResetButton = item.querySelector("[data-mfilter2-group-reset]"),
            mFilter2GroupName = item.getAttribute("data-mfilter2-group"),
            mFilter2GroupInputs = [...item.querySelectorAll(`input[name="${ mFilter2GroupName }"]`)];
        
        if (!mSearch2.getFilters()[`${ mFilter2GroupName }`]) {
            mFilter2GroupResetButton.checked = true;
        }
        
        item.addEventListener("click", event => {
            const target = event.target;
            
            if (target.closest("[data-mfilter2-group-reset]")) {
                mFilter2GroupInputs.map(input => input.checked = false);
                mFilter2GroupResetButton.checked = true;
            }
            
            if (target.closest(`input[name="${ mFilter2GroupName }"]:not([data-mfilter2-group-reset])`)) {
                if (mSearch2.getFilters()[`${ mFilter2GroupName }`]) {
                    mFilter2GroupResetButton.checked = false;
                } else {
                    mFilter2GroupResetButton.checked = true;
                }
            }
        });
    });
    
    mFilter2NumberFilters.forEach(item => {
        const numberInputOne = item.querySelectorAll("input[type='number']")[0],
            numberInputTwo = item.querySelectorAll("input[type='number']")[1],
            numberFilterTextOne = item.querySelector("[data-mfilter2-num-one]"),
            numberFilterTextTwo = item.querySelector("[data-mfilter2-num-two]");
            
        numberFilterTextOne.textContent = miniShop2.Utils.formatPrice(numberInputOne.getAttribute("data-original-value"));
        numberFilterTextTwo.textContent = miniShop2.Utils.formatPrice(numberInputTwo.getAttribute("data-original-value"));
    });
});