document.addEventListener('DOMContentLoaded', function () {
  if (window.innerWidth > 1250) {
    return;
  }

  const burgerButtonList = document.querySelectorAll("[data-burger-button]"),
    burgerMenu = document.querySelector("[data-burger-menu]"),
    burgerMenuDelay = 0.4;

  function replaceBurgerButtonIcon(button, image) {
    const buttonTL = gsap.timeline();

    buttonTL.fromTo(button.querySelector(".icon"), {
      scale: 1,
      opacity: 1,
    }, {
      scale: 0.5,
      opacity: 0,
      duration: defaultAnimationDuration / 2,
      ease: "cubicDefault",
      onComplete: function () {
        button.querySelector(".icon use").setAttribute("xlink:href", `/assets/templates/img/sprite.svg#${image}`);
      }
    });

    buttonTL.fromTo(button.querySelector(".icon"), {
      scale: 0.5,
      opacity: 0,
    }, {
      scale: 1,
      opacity: 1,
      duration: defaultAnimationDuration,
      ease: "cubicDefault",
    });
  }

  function showNavItems() {
    const navItems = document.querySelectorAll("[data-nav-item]"),
      navTL = gsap.timeline();

    burgerMenu.classList.add("burger-menu");

    burgerButtonList.forEach(function (button) {
      button.classList.add("disabled");
      replaceBurgerButtonIcon(button, "cross");
    });

    navItems.forEach(function (item, index) {
      let burgerDelay = index ? `-=${burgerMenuDelay}` : null;

      navTL.fromTo(item, {
        y: 20,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        duration: defaultAnimationDuration,
        ease: "cubicDefault",
      }, burgerDelay);
    });

    if (burgerMenu.querySelector(".nav-mobile__item")) {
      burgerMenu.querySelectorAll(".nav-mobile__item").forEach(function (item) {
        navTL.fromTo(item, {
          y: 20,
          opacity: 0,
        }, {
          y: 0,
          opacity: 1,
          duration: defaultAnimationDuration,
          ease: "cubicDefault",
        }, `-=${burgerMenuDelay}`);
      });
    }

    if (burgerMenu.querySelector(".nav-mobile__order")) {
      burgerMenu.querySelectorAll(".nav-mobile__order").forEach(function (item) {
        navTL.fromTo(item, {
          y: 20,
          opacity: 0,
        }, {
          y: 0,
          opacity: 1,
          duration: defaultAnimationDuration,
          ease: "cubicDefault",
        }, `-=${burgerMenuDelay}`);
      });
    }

    navTL.eventCallback("onComplete", function () {
      burgerButtonList.forEach(function (button) {
        button.classList.remove("disabled");
      });
    });
  }

  function hideNavItems() {
    const navItems = document.querySelectorAll("[data-nav-item]"),
      navTL = gsap.timeline(),
      burgerMenuDelay = 0.47;

    burgerButtonList.forEach(function (button) {
      button.classList.add("disabled");
      replaceBurgerButtonIcon(button, "burger");
    });

    navItems.forEach(function (item, index) {
      let burgerDelay = index ? `-=${burgerMenuDelay}` : null;

      navTL.fromTo(item, {
        y: 0,
        opacity: 1,
      }, {
        y: -20,
        opacity: 0,
        duration: defaultAnimationDuration,
        ease: "cubicDefault",
      }, burgerDelay);
    });

    if (burgerMenu.querySelector(".nav-mobile__item")) {
      burgerMenu.querySelectorAll(".nav-mobile__item").forEach(function (item) {
        navTL.fromTo(item, {
          y: 0,
          opacity: 1,
        }, {
          y: -20,
          opacity: 0,
          duration: defaultAnimationDuration,
          ease: "cubicDefault",
        }, `-=${burgerMenuDelay}`);
      });
    }

    if (burgerMenu.querySelector(".nav-mobile__order")) {
      burgerMenu.querySelectorAll(".nav-mobile__order").forEach(function (item) {
        navTL.fromTo(item, {
          y: 0,
          opacity: 1,
        }, {
          y: -20,
          opacity: 0,
          duration: defaultAnimationDuration,
          ease: "cubicDefault",
        }, `-=${burgerMenuDelay}`);
      });
    }

    navTL.eventCallback("onComplete", function () {
      burgerButtonList.forEach(function (button) {
        burgerMenu.classList.remove("burger-menu");
        button.classList.remove("disabled");
      });
    });
  }

  function showChildren(children, items) {
    const childrenTL = gsap.timeline();

    let height = 0;

    items.forEach(function (item) {
      height = height + item.clientHeight;
    });

    childrenTL.fromTo(children, {
      height: 0,
    }, {
      height: height,
      duration: defaultAnimationDuration,
      ease: "cubicDefault",
    });

    items.forEach(function (item) {
      childrenTL.fromTo(item, {
        x: -20,
        opacity: 0,
      }, {
        x: 0,
        opacity: 1,
        duration: defaultAnimationDuration,
        ease: "cubicDefault",
      }, `-=${burgerMenuDelay}`);
    });
  }

  function hideChildren(children, items) {
    const childrenTL = gsap.timeline();

    childrenTL.to(children, {
      height: 0,
      duration: defaultAnimationDuration,
      ease: "cubicDefault",
    });

    items.forEach(function (item) {
      childrenTL.fromTo(item, {
        x: 0,
        opacity: 1,
      }, {
        x: 20,
        opacity: 0,
        duration: defaultAnimationDuration,
        ease: "cubicDefault",
      }, `-=${burgerMenuDelay}`);
    });
  }

  burgerButtonList.forEach(function (button) {
    button.addEventListener("click", function (event) {
      event.preventDefault();
      if (burgerMenu.classList.contains("burger-menu"))
        hideNavItems();
      else
        showNavItems();
    });
  });

  burgerMenu.querySelectorAll(".nav__link .icon").forEach(function (more) {
    more.addEventListener("click", function (event) {
      event.preventDefault();

      const children = more.closest(".nav__item").querySelector(".nav__children"),
        childrenItems = children.querySelectorAll(".nav__item");

      more.classList.toggle("more-active");

      if (more.classList.contains("more-active"))
        showChildren(children, childrenItems);
      else
        hideChildren(children, childrenItems);
    });
  });
});
