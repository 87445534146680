function sectionCTASectionFirstAnimation(selector = ".cta-section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(function (section) {
      sectionTL = gsap.timeline();

      let sectionBgTime = 5;

      sectionTL.fromTo(
        section,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: defaultAnimationDuration,
          ease: "cubicDefault",
        }
      );

      sectionTL.fromTo(
        section,
        {
          "--cta-gradient": `radial-gradient(138% 33% at -100% -100%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%), 
            radial-gradient(96% 51% at -100% -100%, rgba(250, 204, 214, 1) 0%, rgba(250, 204, 214, 0) 100%), 
            linear-gradient(0deg, rgba(0, 0, 0, 0) -100%, rgba(255, 255, 255, 0.0) -300%, rgba(0, 0, 0, 0) -600.69%),
            radial-gradient(212% 83% at -100% -100%, #8A9BCB -100%, rgba(222, 228, 242, 0) 100%),
            radial-gradient(60% 53% at -100% -100%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
            linear-gradient(0deg, #8FAFD2 -100%, #E4E6F3 49%, #899BCD 200%), #FFF`,
        },
        {
          "--cta-gradient": `radial-gradient(138% 33% at 76% -4%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%), 
            radial-gradient(96% 51% at 150% 24%, rgba(250, 204, 214, 1) 0%, rgba(250, 204, 214, 0) 100%), 
            linear-gradient(332deg, rgba(0, 0, 0, 0) 25%, rgba(255, 255, 255, 0.35) 55%, rgba(0, 0, 0, 0) 90.69%),
            radial-gradient(212% 83% at 100% 100%, #8A9BCB 0%, rgba(222, 228, 242, 0) 100%),
            radial-gradient(60% 53% at 72% 0%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
            linear-gradient(106deg, #8FAFD2 0%, #E4E6F3 49%, #899BCD 99%), #FFF`,
          duration: sectionBgTime,
          ease: "cubicDefault",
          onComplete: function () {
            gsap.fromTo(
              section,
              {
                "--cta-gradient": `radial-gradient(138% 33% at 76% -4%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%), 
        radial-gradient(96% 51% at 150% 24%, rgba(250, 204, 214, 1) 0%, rgba(250, 204, 214, 0) 100%), 
        linear-gradient(332deg, rgba(0, 0, 0, 0) 25%, rgba(255, 255, 255, 0.35) 55%, rgba(0, 0, 0, 0) 90.69%),
        radial-gradient(212% 83% at 100% 100%, #8A9BCB 0%, rgba(222, 228, 242, 0) 100%),
        radial-gradient(60% 53% at 72% 0%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
        linear-gradient(106deg, #8FAFD2 0%, #E4E6F3 49%, #899BCD 99%), #FFF` },
              {
                "--cta-gradient": `radial-gradient(50% 50% at 120% 60%, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0) 100%), 
        radial-gradient(96% 51% at 20% 100%, rgba(250, 204, 214, 0.5) 0%, rgba(0, 0, 0, 0) 100%), 
        linear-gradient(432deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.35) 55%, rgba(0, 0, 0, 0) 90.69%),
        radial-gradient(212% 83% at 0% 100%, #8A9BCB 0%, rgba(222, 228, 242, 0) 100%),
        radial-gradient(60% 53% at 72% 0%, #FFF 0%, rgba(243, 240, 233, 0) 100%),
        linear-gradient(106deg, #8FAFD2 0%, #E4E6F3 60%, #899BCD 130%), #FFF`, duration: 7, repeat: -1, yoyo: true
              },
            );
          },
        }, `-=${defaultAnimationDuration}`
      );

      // cta-section__title
      if (section.querySelector(".cta-section__title")) {
        sectionBgTime = sectionBgTime - 1;
        sectionTL.fromTo(
          section.querySelector(".cta-section__title"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${sectionBgTime}`
        );
      }

      // cta-section__description 
      if (section.querySelector(".cta-section__description")) {
        sectionBgTime = sectionBgTime - 0.25;
        sectionTL.fromTo(
          section.querySelector(".cta-section__description"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${sectionBgTime}`
        );
      }

      // cta-section__list li 
      if (section.querySelector(".cta-section__list")) {
        const block = section.querySelector(".cta-section__list");
        block.querySelectorAll("li").forEach(function (li, index) {
          sectionBgTime = sectionBgTime - 0.25;
          sectionTL.fromTo(
            li,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${sectionBgTime}`
          );
        });
      }

      // .cta-section__menu
      if (section.querySelector(".cta-section__menu")) {
        const block = section.querySelector(".cta-section__menu");
        // .button
        block.querySelectorAll(".button").forEach(function (button, index) {
          sectionBgTime = sectionBgTime - 0.25;
          const y = index % 2 === 0 ? 20 : -20;
          const delay = `-=${sectionBgTime}`;
          sectionTL.fromTo(
            button,
            { opacity: 0, x: y },
            { opacity: 1, x: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
            `-=${sectionBgTime}`
          );
        });
      }

      // .cta-quiz
      if (section.querySelector(".cta-quiz")) {
        const block = section.querySelector(".cta-quiz");
        // .cta-quiz__image
        sectionTL.fromTo(
          section.querySelector(".cta-quiz__image"),
          { opacity: 0, scale: 0.8, transformOrigin: "50% 100%" },
          { opacity: 1, scale: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${sectionBgTime + 1}`
        );
        // .cta-quiz__balloon
        sectionTL.fromTo(
          section.querySelector(".cta-quiz__balloon"),
          { opacity: 0, scale: 0 },
          { opacity: 1, scale: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${sectionBgTime + 1}`
        );
      }

      // .cta-person
      if (section.querySelector(".cta-person")) {
        const block = section.querySelector(".cta-person");
        // .cta-person__image
        sectionTL.fromTo(
          section.querySelector(".cta-person__image"),
          { opacity: 0, scale: 0.8 },
          { opacity: 1, scale: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${sectionBgTime + 1}`
        );
        // .cta-person__content
        sectionTL.fromTo(
          section.querySelector(".cta-person__content"),
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${sectionBgTime + 1}`
        );
      }

      // .cta-image
      if (section.querySelector(".cta-image")) {
        sectionTL.fromTo(
          section.querySelector(".cta-image"),
          { opacity: 0, scale: 0.8, transformOrigin: "50% 100%" },
          { opacity: 1, scale: 1, duration: defaultAnimationDuration, ease: "cubicDefault" },
          `-=${sectionBgTime + 1}`
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });
    });
  }
}
