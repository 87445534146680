document.addEventListener("DOMContentLoaded", function () {
  const cardSelector = `[data-hover-video]`,
    videoSelector = `[data-hover-video-target]`;

  if (!document.querySelector(cardSelector)
    || !document.querySelector(videoSelector)) return;

  if (window.innerWidth <= 1250) {
    document.querySelectorAll(videoSelector).forEach(function (video) {
      video.play();
    });
    return;
  }
  
  document.querySelectorAll(cardSelector).forEach(function (card) {
    const video = card.querySelector(videoSelector);

    card.addEventListener("mouseenter", function () { video.play() });
    card.addEventListener("mouseleave", function () { video.pause() });
  });


});
