document.addEventListener("DOMContentLoaded", function () {

  function processingTargetSection(target, container) {
    targetTL = gsap.timeline();

    ScrollTrigger.create({
      animation: targetTL,
      trigger: target,
      start: "top 50%",
      end: "bottom 50%",
      scrub: false,
      onToggle: function (self) {
        // Обновляем активную ссылку
        if (self.isActive) {
          let id;
          if (target.getAttribute("data-product-scroll-target")) {
            id = target.getAttribute("data-product-scroll-target");
          } else {
            id = target.getAttribute("id");
          }
          document.querySelectorAll("[data-product-scroll-link]").forEach(function (link) {
            if (link.getAttribute("href").split("#")[1] == id) {
              updateActiveLink(link, container);
            }
          });
        }
      },
    });
  }

  function clearAllActiveLinks() {
    document.querySelectorAll(".product-scroll__link_active").forEach(function (item) {
      item.classList.remove("product-scroll__link_active");
    });
  }

  function updateActiveLink(link, container) {
    clearAllActiveLinks();
    link.classList.add("product-scroll__link_active");

    gsap.set(container, {
      "--before-left-position": link.offsetLeft + "px",
      "--before-width": link.clientWidth + "px"
    });
  }

  function productHeaderSticky(header, headerHidden) {
    /* Этот вариант отрабатывал при пересечении навигацией верхней границы экрана. */
    /* ScrollTrigger.create({
      trigger: header,
      start: "top 10px",
      end: "bottom 100%",
      scrub: false,
      markers: true,
      onEnter: function (self) {
        headerHidden.classList.add("product-scroll__hidden_active");
      },
      onLeaveBack: function (self) {
        headerHidden.classList.remove("product-scroll__hidden_active");
      }
    }); */
    ScrollTrigger.create({
      trigger: document.querySelector("[data-product-scroll]"),
      start: "top top",
      end: "bottom 40%",
      scrub: false,
      onToggle: function (self) {
        if (!self.isActive) {
          headerHidden.classList.remove("product-scroll__hidden_active");
        } else {
          headerHidden.classList.add("product-scroll__hidden_active");
        }
      }
    });
  }

  if (document.querySelector("[data-product-scroll-link]")) {

    if (!document.querySelector("[data-product-scroll-target]")) {
      console.debug("Product scroll target found");
      return;
    }

    if (!document.querySelector("[data-product-scroll-list]")) {
      console.debug("Product scroll list not found");
      return;
    }

    if (!document.querySelector("[data-product-scroll-header]")) {
      console.debug("Product scroll header not found");
      return;
    }

    if (!document.querySelector("[data-product-scroll]")) {
      console.debug("Product scroll not found");
      return;
    }

    const header = document.querySelector("[data-product-scroll-header]"),
      headerHidden = document.querySelector("[data-product-scroll-hidden]"),
      container = document.querySelectorAll("[data-product-scroll-list]");

    document.querySelectorAll("[data-product-scroll-target]").forEach(function (target) {
      container.forEach(function (item) {
        processingTargetSection(target, item);
      });
    });

    productHeaderSticky(header, headerHidden);

  }
});
