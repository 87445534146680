document.addEventListener("click", event => {
    
    const mainButtomSelector = "[data-src='#connect-with-us']",
        mainModalTitleSelector = "#connect-with-us [data-modal-title]",
        mainModalDescriptionSelector = "#connect-with-us [data-modal-description]",
        mainModalButtonSelector = "#connect-with-us [data-modal-button]",
        mainModalFormNameSelector = "#connect-with-us [data-modal-form-name]";
    
    const target = event.target;
    
    if (target.closest(mainButtomSelector)) {
        if (target.closest(mainButtomSelector).getAttribute("data-main-form-pagetitle")) {
            document.querySelector(mainModalTitleSelector).textContent = target.closest(mainButtomSelector).getAttribute("data-main-form-pagetitle");
        }
        if (target.closest(mainButtomSelector).getAttribute("data-main-form-description")) {
            document.querySelector(mainModalDescriptionSelector).textContent = target.closest(mainButtomSelector).getAttribute("data-main-form-description");
        }
        if (target.closest(mainButtomSelector).getAttribute("data-main-form-button")) {
            document.querySelector(mainModalButtonSelector).textContent = target.closest(mainButtomSelector).getAttribute("data-main-form-button");
        }
        if (target.closest(mainButtomSelector).getAttribute("data-main-form-name")) {
            document.querySelector(mainModalFormNameSelector).value = target.closest(mainButtomSelector).getAttribute("data-main-form-name");   
        }
    }
});